<template>
  <div class="gyroAccess is-flex is-flex-direction-column" style="position: relative;">
    <slot :DeviceOrientationEnabled="DeviceOrientationEnabled" :dataStarted="dataStarted" :callibrated="callibrated"
      :alpha="orientation.alpha" :beta="orientation.beta" :gamma="orientation.gamma" :x="coords.x" :y="coords.y" :acceleration="acceleration" :noGyro="noGyro"
      :constrainedPosition="constrainedPosition">
    </slot>
  </div>
</template>

<script>
const utils = require("./utils");

export default {
  data() {
    return {
      callibrated: false,
      DeviceOrientationEnabled: false,
      dataStarted: false,
      noGyro: true,
      orientation: {},
      acceleration: {},
      offsetAlpha: 0,
      offsetBeta: 0,
      offsetGamma: 0,
      constrainedPosition: {x: .5, y: .5},
      prevOrientation: null,
    }
  },
  props: {
    range: {
      type: Number,
      required: false,
      default: 30, // Range of deegress to map into 0~1
    },
  },
  methods: {
    CheckPermission() {
      // Check if permission status is stored in local storage
      if (localStorage.getItem('gyroscopePermission') === 'granted') {
          return true;
      }
      return false;
    },
    AutoStartGyro(){
      // Permission is already granted, add event listener
      if (window.DeviceOrientationEvent) {
        window.addEventListener('deviceorientation', this.HandleOrientationData)
        window.addEventListener('devicemotion', this.HandleMotionData, true)
      } else {
          console.log("Device does not have a gyroscope.");
      }

    },
    RequestGyro() {
      console.log("RequestGyro access");
      if (this.DeviceOrientationsNeedsRequest()) {
        console.log("requesting permission");
        DeviceOrientationEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === 'granted') {
              localStorage.setItem('gyroscopePermission', 'granted');
              window.addEventListener('deviceorientation', this.HandleOrientationData)
              window.addEventListener('devicemotion', this.HandleMotionData, true)
              this.$emit('gyroStarted')
            } else {
              // Permission denied
              this.$emit('gyroPermissionDenied')
            }
          })
          .catch(console.error);
      } else {
        if (window.DeviceOrientationEvent) {
          // console.log("permission not necessary");
          // handle regular non iOS 13+ devices
          // window.addEventListener('deviceorientation', (e) => { this.HandleGyroData(e) });
          window.addEventListener('deviceorientation', this.HandleOrientationData)
          window.addEventListener('devicemotion', this.HandleMotionData, true)
          this.$emit('gyroStarted')
        } else {
          // console.log("no gyro available");
          this.$emit('noGyroAvailable')
        }
      }
    },
    HandleOrientationData(evt) {
      this.dataStarted = true;
      // solo 3 decimales
      this.orientation = {
        alpha: evt.alpha,
        beta: evt.beta,
        gamma: evt.gamma
      }

      
      if(this.prevOrientation){
        let deltaAlpha = this.orientation.alpha - this.prevOrientation.alpha;
        if (deltaAlpha > 180) {
            deltaAlpha -= 360;
        } else if (deltaAlpha < -180) {
            deltaAlpha += 360;
        }

        let deltaBeta = this.orientation.beta - this.prevOrientation.beta;
        if (deltaBeta > 180) {
            deltaBeta -= 360;
        } else if (deltaBeta < -180) {
            deltaBeta += 360;
        }

        // Update constrained position
        this.constrainedPosition.x -= deltaAlpha / this.range;
        this.constrainedPosition.y -= deltaBeta / this.range;

        // Clamp constrained position
        this.constrainedPosition.x = utils.clamp(this.constrainedPosition.x, 0, 1);
        this.constrainedPosition.y = utils.clamp(this.constrainedPosition.y, 0, 1);
      }

      this.prevOrientation = this.orientation;

    },
    HandleMotionData(evt) {
      console.log("acceleration", evt)
      this.dataStarted = true;
      this.acceleration = {
        x: evt.x,
        y: evt.y,
        z: evt.z
      }
    },
    Callibrate() {
      console.log("Callibrated")
      this.offsetAlpha = this.orientation.alpha;
      this.offsetBeta = this.orientation.beta;
      this.offsetGamma = this.orientation.gama;

      this.callibrated = true;
      this.$emit('callibrated')
    },
    DeviceOrientationsNeedsRequest() {
      return typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function'
    },
  },
  computed: {
    coords() {
      
      let x = this.orientation.alpha - this.offsetAlpha;
      let y = this.orientation.beta - this.offsetBeta;

      if (Math.abs(x) > 180) x += x > 0 ? -360 : 360;

      x += this.range;
      x /= this.range * 2;
      x = 1 - utils.clamp(x, 0, 1);
      y = 1 - utils.map(y, -this.range, this.range, 0, 1)

      // 3 numeros decimales.
      x = Math.round(x * 1e3) / 1e3;
      y = Math.round(y * 1e3) / 1e3;
      return { x, y }
    }
  },
  watch: {
    coords() {
      this.$emit('moved', {
        coords: this.coords,
        orientation: this.orientation,
        acceleration: this.acceleration,
        constrained: this.constrainedPosition
      })
    },
    dataStarted() {
      this.noGyro = !this.DeviceOrientationEnabled && !this.dataStarted
    },
    DeviceOrientationEnabled() {
      this.Network = !this.DeviceOrientationEnabled && !this.dataStarted
    }
  },
  mounted() {
    if(this.CheckPermission()){
      this.AutoStartGyro();
    }else{
      this.RequestGyro();
    }
  },
  beforeDestroy() {
    // remove listener
    window.removeEventListener("deviceorientation", this.HandleOrientationData)
    window.removeEventListener("devicemotion", this.HandleMotionData)
  }
};
</script>